import React from 'react'
import { graphql } from 'gatsby'

import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'



class PhotoPage extends React.Component {
  render() {

    const { data } = this.props;
    const photos = data.allMarkdownRemark.edges

    return (
      <Layout>
        <SEO title="Photos" />


        {photos.map(({ node }) => {
          return (
            <section className="gallery" key={node.frontmatter.title}>
              <header>
                <h1>{node.frontmatter.title}</h1>
              </header>
              <div dangerouslySetInnerHTML={{ __html: node.html }} />
            </section>
            )
          })}
      </Layout>
    )
  }
}

export default PhotoPage

export const pageQuery = graphql`
query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }, filter: { frontmatter:  { type: { eq:"photos"}}}) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            type
            date(formatString: "MMMM DD, YYYY")
            author
          }
          html
        }
      }
    }
  }
`
